<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          v-on="on"
          :disabled="!photo"
          @click="open"
        >
          <v-icon>fa fa-copy</v-icon>
        </v-btn>
      </template>

      <span>{{'action.copy_link'| t}}</span>
    </v-tooltip>

    <photoSizeListSelection
      :photo="photo"
      :choice="choice"
      v-model="bottomSheetActive"
    ></photoSizeListSelection>
  </div>
</template>

<script lang="babel" type="text/babel">
import photoItemAction from '@/components/photoSelector/action/photoItemAction'
export default {
  mixins: [photoItemAction],
  methods: {
    async open() {
      if(this.immediatelyPhoto) {
        this.copy(this.immediatelyUrl)
        return
      }

      this.bottomSheetActive = true
    },
    async choice(sizeListItem) {
      this.bottomSheetActive = false
      this.copy(sizeListItem.url, document.querySelector('.v-dialog'))
    },
    async copy(data, container = null) {
      if(!container) container = document.body
      try {
        await this.$copyText(data, container)
      } catch (error) {
        console.warn(error)
      } finally {
        this.$snotify.success(null, this.$t('copy.successfully'))
      }
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>